import * as React from "react"
import { Link } from "gatsby"
import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import Insight from "gatsby-theme-starberry-lomondgroup/src/components/NewsInsight/Insight";
import { breadcrumbs } from "gatsby-theme-starberry-lomondgroup/src/common/utils/breadcrumbs";

// markup
const NotFoundPage = () => {
  const bread = [
    { label: '404', slug: '404' }
  ]
  const breadcrumbsList = breadcrumbs(null, bread);
  
  return (
    <div className="search-header property-header news-page not-found-page">
      <Layout seo_meta={{ title: '404 page', description: '404 page' }} menu_slug={`/404`}>
        <Insight
          title={"We’re sorry, the page you were looking for cannot be found."}
          content={``} 
          breadcrumbs={breadcrumbsList} 
        />
        <div className="btns">
          <Link className={`btn btn-primary first-btn`} to={`/`}>  Back to home page </Link>
          {/* <Link className={`btn btn-primary`} to={`/${PageLinks.property_to_rent}/in-${defaultArea_Slug}`}> Property Search </Link> */}
        </div>


      </Layout>
    </div>
  )
}

export default NotFoundPage